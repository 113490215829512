<!--
 * @Description: 创建开通服务单
 * @Author: ChenXueLin
 * @Date: 2021-10-12 13:39:18
 * @LastEditTime: 2022-06-22 10:33:14
 * @LastEditors: ChenXueLin
-->
<template>
  <div
    class="content-view-wrapper pd-10"
    v-loading="loading"
    :element-loading-background="loadingBackground"
  >
    <div class="content-view ">
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 基本信息 start -->
          <template>
            <div class="primaryTitle">开通服务单</div>
            <div class="baseInfo-box">
              <div class="edit-title">基本信息</div>
              <el-form
                ref="baseInfoForm"
                class="editForm"
                label-position="right"
                :inline="true"
                label-width="120px"
                :rules="rules"
                :model="baseInfoForm"
              >
                <el-form-item label="客户名称" prop="corpId">
                  <e6-vr-select
                    v-model="baseInfoForm.corpId"
                    :data="corpList"
                    placeholder="客户名称"
                    title="客户名称"
                    clearable
                    virtual
                    remote
                    :is-title="true"
                    @filterChange="handleLoadCorpFilter"
                    @change="handleChange"
                    :props="{
                      id: 'corpId',
                      label: 'corpName'
                    }"
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="指派类型" prop="solueIsDept">
                  <el-radio-group v-model="baseInfoForm.solueIsDept">
                    <el-radio :label="1">部门</el-radio>
                    <el-radio :label="0">员工</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="指派部门"
                  prop="departmentId"
                  v-if="baseInfoForm.solueIsDept == 1"
                >
                  <!-- 部门下拉框 -->
                  <e6-select
                    v-model="baseInfoForm.departmentId"
                    :dataList="treeData"
                    :filterable="true"
                    :slotTemplate="'tree'"
                    :multiple="false"
                    :issingleleaf="false"
                    ref="t_select"
                  ></e6-select>
                </el-form-item>
                <el-form-item label="指派处理人" prop="solveRpid" v-else>
                  <all-user-search
                    v-model="baseInfoForm.solveRpid"
                    clear
                    placeholder="指派处理人"
                    title="指派处理人"
                    :propsConfig="{
                      id: 'employeeId',
                      label: 'userName'
                    }"
                  >
                  </all-user-search>
                </el-form-item>
                <el-form-item label="联系人" prop="linkmanrpid">
                  <div class="select-content">
                    <e6-vr-select
                      v-model="baseInfoForm.linkmanrpid"
                      :data="contactList"
                      placeholder="联系人"
                      title=""
                      :props="{
                        id: 'contactId',
                        label: 'contactName'
                      }"
                      clearable
                      @change="handleContact"
                      @clear="handleClear"
                    ></e6-vr-select>
                    <el-button
                      type="text"
                      style="margin-left: 10px"
                      @click="addConcat"
                    >
                      添加联系人
                    </el-button>
                  </div>
                </el-form-item>
                <el-form-item label="联系电话" prop="contactPhone">
                  <el-input
                    placeholder="联系电话"
                    v-model="baseInfoForm.contactPhone"
                  ></el-input>
                </el-form-item>

                <el-form-item label="要求完成时间" prop="expecttime">
                  <el-date-picker
                    v-model="baseInfoForm.expecttime"
                    type="datetime"
                    placeholder="选择日期"
                    value-format="timestamp"
                    popper-class="special"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="联系地址" prop="defaultCity">
                  <e6-vr-select
                    style="width:300px"
                    v-model="baseInfoForm.defaultCity"
                    :data="addressList"
                    placeholder="联系地址"
                    title="联系地址"
                    virtual
                    clearable
                    :is-title="true"
                    remote
                    @filterChange="handleLoadAddressFilter"
                    @change="handleCitySelect"
                    :props="{
                      id: 'fullName',
                      label: 'fullName'
                    }"
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="详细地址" prop="insAddress">
                  <el-input
                    placeholder="详细地址"
                    v-model="baseInfoForm.insAddress"
                  ></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                  <el-input
                    v-model="baseInfoForm.remark"
                    placeholder="请输入备注"
                    type="textarea"
                    style="width: 500px"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </template>
          <!-- 基本信息 end -->
          <!-- 任务项设置   start -->
          <template>
            <div class="edit-title">任务项设置</div>
            <div class="edit-content" style="margin-bottom: 20px">
              <div class="associated-info">
                <div class="right-button">
                  <el-button type="primary" @click="addScene">
                    添加任务项
                  </el-button>
                  <el-button type="primary" @click="handleDelete">
                    删除任务项
                  </el-button>

                  <!-- <el-button type="primary">
                    导入任务项
                  </el-button> -->
                </div>
              </div>
              <el-table
                ref="multipleTable"
                :data="tableData"
                highlight-current-row
                :row-key="row => row.returnId"
                @selection-change="handleSelectionChange"
              >
                <el-table-column
                  type="selection"
                  width="50"
                  :reserve-selection="true"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="sceneName"
                  label="场景名称"
                  width="160"
                  align="center"
                  header-align="center"
                  fixed
                >
                  <template slot-scope="{ row }">
                    <span v-if="row.show">{{ row.sceneName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="sceneTypeName"
                  label="场景类型"
                  width="160"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="{ row }">
                    <span v-if="row.show">{{ row.sceneTypeName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="secondClassName"
                  label="安装组合"
                  width="160"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="{ row }">
                    <span v-if="row.show2">{{ row.secondClassName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="thirdClassName"
                  label="开通商品"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="firstClassName"
                  label="商品分类"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="equipCode"
                  label="设备编号"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="realNo"
                  label="SIM卡号"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="stopDateStr"
                  label="卡报停时间"
                  width="200"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="requestDate"
                  label="要求开通时间"
                  width="300"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="scope">
                    <el-date-picker
                      v-model="scope.row.requestDate"
                      type="datetime"
                      placeholder="选择日期"
                      value-format="timestamp"
                      popper-class="special"
                      default-time="['00:00:00', '23:59:59']"
                      style="width:200px"
                      :picker-options="pickerOptions"
                    ></el-date-picker>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </div>
      </section>
      <!-- 选择场景 -->
      <select-scene
        :selectSceneDialog="selectSceneDialog"
        @handleClose="handleClose"
        @getData="getData"
        :terStatus="3"
        :corpId="baseInfoForm.corpId"
      ></select-scene>
      <!-- terStatus:3查询报停设备 -->
      <!-- 添加联系人弹框 -->
      <add-concat-dialog
        :addContactVisible="addContactVisible"
        @handleClose="handleClose"
        :corpName="baseInfoForm.corpName"
        :corpId="baseInfoForm.corpId"
        @updateList="updateList"
        :isNeedReq="true"
      ></add-concat-dialog>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import SelectScene from "@/components/workOrderManage/selectScene.vue";
import AddConcatDialog from "@/components/addConcatDialog.vue";
import addTask from "@/mixins/addTask";
import addService from "@/mixins/addService";
import { addOpenService } from "@/api";
import { printError } from "@/utils/util";
import allUserSearch from "@/components/allUserSearch";
export default {
  name: "openService",
  components: { SelectScene, AddConcatDialog, allUserSearch },
  data() {
    return {
      baseInfoForm: {
        corpId: "",
        linkmanrpid: "",
        solueIsDept: 1, //指派类型1部门0员工
        departmentId: [], //指派部门
        solveRpid: "", //指派处理人，
        insAddress: "", //详细地址
        contactPhone: "", //电话
        defaultCity: "", //默认选中地址
        expecttime: "",
        remark: ""
      }, //基本信息
      addContactVisible: false, //添加联系人弹框
      //批量填写弹框
      tableData: [], //
      selectCloumn: [], //要填写的数据
      pickerOptions1: {
        onPick(time) {
          console.log(time, "onClick===");
        }
      }
    };
  },
  mixins: [base, addTask, addService],
  created() {},
  watch: {},
  methods: {
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.baseInfoForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //点击确定
    submit() {
      this.$refs.baseInfoForm.validate(valid => {
        if (valid) {
          if (!this.tableData.length) {
            this.$message.warning("请至少选择一个任务项");
            return;
          }
          this.baseInfoForm.contactName = this.filterSelect(
            this.contactList,
            "contactId",
            this.baseInfoForm.linkmanrpid
          ).contactName;
          this.baseInfoForm.corpName = this.filterSelect(
            this.corpList,
            "corpId",
            this.baseInfoForm.corpId
          ).corpName;
          this.addOpenServiceReq();
        }
      });
    },
    //创建开通服务单请求
    async addOpenServiceReq() {
      try {
        let res = await addOpenService({
          ...this.baseInfoForm,
          solveRpid:
            this.baseInfoForm.solueIsDept == 1
              ? this.baseInfoForm.departmentId.join("")
              : this.baseInfoForm.solveRpid,
          items: this.tableData
        });
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.closeTag(this.$route);
          this.routerPush({
            name: "workOrderManage/serviceListManage",
            params: {
              refresh: true
            }
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-content {
  padding: 20px 20px 2px;
  .e6-vr-select {
    width: 100%;
  }
}
.associated-info {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.table-input {
  width: 300px;
}
/deep/.write-dialog {
  .el-dialog__body {
    padding: 20px;
    .el-input {
      width: 220px;
    }
  }
}
</style>
